import { createSlice, createSelector } from '@reduxjs/toolkit';
import { multiply, add, dinero } from 'dinero.js';
import { USD } from '@dinero.js/currencies';

import moneyUtil from 'src/util/moneyUtil';

const initialState = {
  products: {},
};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadProducts(state, action) {
      // Resetting the products to avoid cached products
      const prevProds = state.products;
      state.products = {};
      for (const product of action.payload) {
        if (product.productStatus && product.priceAmount !== '') {
          state.products[product.productId] = {
            name: product.productName,
            description: product.productDescription ?? '',
            quantity: {
              start: 0,
              update: prevProds[product.productId]?.quantity.update ?? 0,
            },
            isChecked: prevProds[product.productId]?.isChecked ?? false,
            price: {
              id: product.priceId,
              unitAmountDecimal: product.priceAmount,
            },
          };
        }
      }
    },
    loadSubscriptionItems(state, action) {
      const data = action.payload.body.data;
      if (data.subscriptionItems === undefined) {
        return;
      }

      // Resetting the products to avoid cached products
      state.products = {};
      const items = data.subscriptionItems;
      for (const item of items) {
        state.products[item.productId] = {
          name: item.productName,
          description: item.productDescription ?? '',
          quantity: {
            start: item.quantity,
            update: item.quantity,
          },
          isChecked: true,
          price: {
            id: item.priceId,
            unitAmountDecimal: item.priceAmount,
          },
          subscriptionItem: item.id,
        };
      }
    },
    loadNew(state, action) {
      for (const product of action.payload) {
        if (
          product.productStatus &&
          product.priceAmount !== '' &&
          state.products[product.productId]?.subscriptionItem === undefined
        ) {
          state.products[product.productId] = {
            name: product.productName,
            description: product.productDescription ?? '',
            quantity: {
              start: 0,
              update: 0,
            },
            isChecked: false,
            price: {
              id: product.priceId,
              unitAmountDecimal: product.priceAmount,
            },
          };
        }
      }
    },
    updateQuantity(state, action) {
      const { product, change } = action.payload;
      const oldQuantity = state.products[product].quantity.update;
      const newQuantity = Math.min(1000, Math.max(0, oldQuantity + change));
      state.products[product].quantity.update = newQuantity;
    
      if (newQuantity > oldQuantity) {
        state.products[product].isChecked = true;
      } else if (newQuantity === 0) {
        state.products[product].isChecked = false;
      }
    },
    setQuantity(state, action) {
      const { product, quantity } = action.payload;
    
      // If the current quantity is 0 and the user is typing a non-zero value
      if (state.products[product].quantity.update === 0 && quantity !== 0) {
        state.products[product].quantity.update = quantity; // Directly set the input quantity
      } else {
        const newQuantity = Math.min(1000, Math.max(0, quantity));
        state.products[product].quantity.update = newQuantity;
      }
    
      // Update the isChecked flag based on the new quantity
      if (state.products[product].quantity.update > 0) {
        state.products[product].isChecked = true;
      } else {
        state.products[product].isChecked = false;
      }
    },
    flipCheck(state, action) {
      const product = action.payload;
      state.products[product].isChecked = !state.products[product].isChecked;

      if (state.products[product].quantity.update === 0 && state.products[product].isChecked) {
        state.products[product].quantity.update = 1;
      }
    },
    clearNewProducts(state) {
      for (const [product, value] of Object.entries(state.products)) {
        if (value.subscriptionItem === undefined) {
          delete state.products[product];
        }
      }
    },
  },
});

const selectProducts = (state) => state[slice.name].products;
const selectLineItems = createSelector([selectProducts], (products) =>
  Array.from(Object.entries(products))
    .map(([key, value]) => ({ ...value, id: key }))
    .filter((product) => product.quantity.update >= 1 && product.isChecked)
    .map((item) => ({
      quantity: item.quantity.update,
      price: item.price.id,
    }))
);

const unitAmountsDecimalSelector = createSelector([selectProducts], (products) =>
  Object.values(products).map((product) => product.price.unitAmountDecimal)
);

const quantityStartsSelector = createSelector([selectProducts], (products) =>
  Object.values(products).map((product) => product.quantity.start)
);

const quantityUpdatesSelector = createSelector([selectProducts], (products) =>
  Object.values(products).map((product) => product.quantity.update)
);

const areCheckedSelector = createSelector([selectProducts], (products) =>
  Object.values(products).map((product) => product.isChecked)
);

const updatedItemsSelector = createSelector([selectProducts], (products) => {
  return Object.values(products)
    .map((product) => ({
      id: product.subscriptionItem,
      startQuantity: product.quantity.start,
      updateQuantity: product.isChecked ? product.quantity.update : 0,
      price: product.price.id,
    }))
    .filter((product) => product.id !== undefined || product.updateQuantity > 0)
    .map((product) => ({
      id: product.id,
      quantity: product.updateQuantity,
      price: product.price,
    }));
});

const cartSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    product: {
      length: createSelector([selectProducts], (products) => Object.keys(products).length),
      ids: createSelector([selectProducts], (products) => Object.keys(products)),

      name: (product) => (state) => state[slice.name].products[product].name,
      description: (product) => (state) => state[slice.name].products[product].description,

      quantity: {
        start: (product) => (state) => state[slice.name].products[product].quantity.start,
        starts: quantityStartsSelector,

        update: (product) => (state) => state[slice.name].products[product].quantity.update,
        updates: quantityUpdatesSelector,
      },

      isChecked: (product) => (state) => state[slice.name].products[product].isChecked,
      areChecked: areCheckedSelector,

      price: {
        unitAmountDecimal: (product) => (state) =>
          state[slice.name].products[product].price.unitAmountDecimal,

        unitAmountsDecimal: unitAmountsDecimalSelector,

        ids: createSelector([selectProducts], (products) =>
          Object.values(products).map((product) => product.price.id)
        ),
      },

      subscriptionItems: createSelector([selectProducts], (products) =>
        Object.values(products).map((product) => product.subscriptionItem)
      ),

      lineItems: selectLineItems,
      hasNoLineItems: createSelector([selectLineItems], (lineItems) => {
        const totalQuantities = lineItems.reduce((acc, curr) => acc + curr.quantities, 0);
        return totalQuantities === 0;
      }),

      subtotal: createSelector(
        [unitAmountsDecimalSelector, quantityUpdatesSelector, areCheckedSelector],
        (unitAmountsDecimal, quantityUpdates, areChecked) => {
          return unitAmountsDecimal
            .map((amount) => moneyUtil.dineroFromDecimal({ amount, currency: USD }))
            .map((amount, i) => {
              const quantity = { amount: quantityUpdates[i], currency: USD };
              return multiply(amount, quantity);
            })
            .filter((amount, i) => areChecked[i])
            .reduce((acc, curr) => add(acc, curr), dinero({ amount: 0, currency: USD }));
        }
      ),

      updatedItems: updatedItemsSelector,
      isNotUpdated: createSelector(
        [updatedItemsSelector, quantityStartsSelector],
        (updatedItems, quantityStarts) =>
          updatedItems.every((item, i) => item.quantity === quantityStarts[i])
      ),
    },
  },
  rememberedKeys: [],
};

export default cartSlice;
