import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import SecondaryButton from 'src/components/secondaryButton/SecondaryButton';

import styles from 'src/components/entryPage/EntryPage.module.css';
import styling from 'src/components/loginPage/LoginPage.module.css';
import { m } from 'src/util/cssUtil';
import egainLogo from 'src/img/egainLogo.png';

export default function Header() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1000);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 550);
      setIsTablet(window.innerWidth < 1000);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={m(styles.headerWrapper, 'd-flex')}>
      <div
        className={m(
          styles.topBar,
          'd-flex',
          'align-items-center',
          'justify-content-between',
          'w-100',
          'pe-3'
        )}
      >
        <div className={m(styles.containerCommon, 'px-0')}>
          <a href="https://egainstg.wpengine.com/rigel-dev/">
            <img
              src={egainLogo}
              className={styles.headerLogo}
              alt="eGain Logo"
            />
          </a>
        </div>

        {isMobile ? (
          <div
            className={m(
              styles.topBar,
              'd-flex',
              'align-items-center',
              'justify-content-end'
            )}
          >
            <SecondaryButton
              onClick={() => navigate('/try')}
              className={m(styling.trialBtn, 'me-3')}
            >
              {t('free_trial')}
            </SecondaryButton>
            <Button
              type="button"
              variant="primary"
              onClick={() => navigate('/buy')}
              className={m(styling.buyNowBtn)}
            >
              {t('buy_now')}
            </Button>
          </div>
        ) : isTablet ? (
          <div
            className={m(
              styles.topBar,
              'd-flex',
              'align-items-center',
              'justify-content-end'
            )}
            style={{ marginRight: '5.5rem' }}
          >
            <SecondaryButton
              onClick={() => navigate('/try')}
              className={m(styling.trialBtn, 'me-3')}
            >
              {t('free_trial')}
            </SecondaryButton>
            <Button
              type="button"
              variant="primary"
              onClick={() => navigate('/buy')}
              className={m(styling.buyNowBtn)}
            >
              {t('buy_now')}
            </Button>
          </div>
        ) : (
          <div
            className={m(
              styles.topBar,
              styling.loginTopBar,
              'd-flex',
              'align-items-center',
              'justify-content-end'
            )}
          >
            <SecondaryButton
              onClick={() => navigate('/try')}
              className={m(styling.trialBtn, 'me-3')}
            >
              {t('free_trial')}
            </SecondaryButton>
            <Button
              type="button"
              variant="primary"
              onClick={() => navigate('/buy')}
              className={m(styling.buyNowBtn)}
            >
              {t('buy_now')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
