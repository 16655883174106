import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notification: 'closed',
};

const slice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    open(state) {
      state.notification = 'open';
    },
    close(state) {
      state.notification = 'closed';
    },
  },
});

const notifySlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    notification: (state) => state[slice.name].notification,
  },
  rememberedKeys: [slice.name],
};

export default notifySlice;
