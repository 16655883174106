import React from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import ReactDOM from 'react-dom/client';
import App from 'src/App';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
