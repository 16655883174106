// This is a wrapper for fetch that will throw if the response is not ok
// Required by react-query which expects any errors to be thrown
export const fetchWithThrow = async (url, options) => {
  const response = await fetch(url, options);

  if (!response.ok) {
    let body = undefined;
    try {
      body = await response.json();
    } catch {}

    const error = new Error(response.statusText);
    error.response = { body, status: response.status };
    throw error;
  }

  const body = await response.json();
  return {
    code: response.status,
    body,
  };
};
export const fetchWithReturn = async (url, options) => {
  const response = await fetch(url, options);

  const body = await response.json();
  return {
    code: response.status,
    body,
  };
};

export const code = {
  ok: 200,
  badRequest: 400,
  unauthorized: 401,
  conflict: 409,
  gone: 410,
  internalServerError: 500,
};

const apiUtil = {
  fetchWithThrow,
  code,
  fetchWithReturn
};

export default apiUtil;
