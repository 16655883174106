import apiUtil from 'src/apis/apiUtil';

export const key = 'getUserTenants';
export const fn = (emailId) => {
  const url =
    `${process.env.REACT_APP_BACKEND_BASE_URL}/get-tenant?emailId=${emailId}`;

  return apiUtil.fetchWithThrow(url, {
    method: 'get',
  });
};

const getUserTenants = {
  key,
  fn,
};

export default getUserTenants;
