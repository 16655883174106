import { configureStore } from '@reduxjs/toolkit';
import { rememberReducer, rememberEnhancer } from 'redux-remember';

import journeySlice from 'src/components/journey/journeySlice';
import cartSlice from 'src/components/cart/cartSlice';
import checkoutSlice from 'src/components/checkout/checkoutSlice';
import authorizerSlice from 'src/authorizer/authorizerSlice';
import myAccountSlice from 'src/pages/myaccount/myAccountSlice';
import thankYouSlice from 'src/pages/thankyou/thankYouSlice';
import aboutSubSlice from 'src/components/aboutSub/aboutSubSlice';
import modifySlice from 'src/pages/modify/modifySlice';
import notifySlice from 'src/components/adminPage/notify/notifySlice';
import waitingSlice from 'src/pages/waiting/waitingSlice';

const reducers = {
  ...journeySlice.reducers,
  ...cartSlice.reducers,
  ...checkoutSlice.reducers,
  ...authorizerSlice.reducers,
  ...myAccountSlice.reducers,
  ...thankYouSlice.reducers,
  ...aboutSubSlice.reducers,
  ...modifySlice.reducers,
  ...notifySlice.reducers,
  ...waitingSlice.reducers
};

const rememberedKeys = [
  ...journeySlice.rememberedKeys,
  ...cartSlice.rememberedKeys,
  ...checkoutSlice.rememberedKeys,
  ...authorizerSlice.rememberedKeys,
  ...myAccountSlice.rememberedKeys,
  ...thankYouSlice.rememberedKeys,
  ...aboutSubSlice.rememberedKeys,
  ...modifySlice.rememberedKeys,
  ...notifySlice.rememberedKeys,
  ...waitingSlice.rememberedKeys
];

const reducer = rememberReducer(reducers);

const initStore = (options) =>
  configureStore({
    reducer,
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(
        rememberEnhancer(
          // or window.sessionStorage, or your own custom storage driver
          options.driver,
          rememberedKeys
        )
      ),
    preloadedState: options.preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: { warnAfter: 128 },
        serializableCheck: { warnAfter: 128 }
      })
  });

export default initStore;
