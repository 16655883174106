import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerId: '',
  subscriptionId: '',
  isTrialToProd: false,
};

const slice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    updateCustomerId(state, action) {
      state.customerId = action.payload;
    },
    updateSubscriptionId(state, action) {
      state.subscriptionId = action.payload;
    },
    setIsTrialToProd(state, action) { // New action to update isTrialToProd state
      state.isTrialToProd = action.payload;
    }
  },
});

const myAccountSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    customerId: (state) => state[slice.name].customerId,
    subscriptionId: (state) => state[slice.name].subscriptionId,
    isTrialToProd: (state) => state[slice.name].isTrialToProd,
  },
  rememberedKeys: [],
};

export default myAccountSlice;
