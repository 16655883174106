import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tenantType: 'unknown',
  username: null,
  firstName: null,
};

const slice = createSlice({
  name: 'authorizer',
  initialState,
  reducers: {
    loadTenantType(state, action) {
      state.tenantType = action.payload;
    },
    clearTenantType(state) {
      state.tenantType = initialState.tenantType;
    },
    loadUsername(state, action) {
      state.username = action.payload;
    },
    loadFirstName(state, action) {
      state.firstName = action.payload;
    },
  },
});

const authorizerSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    tenantType: (state) => state[slice.name].tenantType,
    username: (state) => state[slice.name].username,
    firstName: (state) => state[slice.name].firstName,
  },
  rememberedKeys: [slice.name],
};

export default authorizerSlice;
