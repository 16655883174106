import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import FormGroup from 'src/pages/signup/detailsForm/FormGroup';
import infoIcon from 'src/img/info_icon.svg';

import styles from 'src/pages/signup/SignUp.module.css';
import { mergeIf, m } from 'src/util/cssUtil';
import errorIcon from 'src/img/error_icon.svg';

export default function DetailsField({
  label,
  type,
  placeholder,
  invalidFeedback,
  controlId,
  input,
  onFocus,
  className,
  isError = false,
  infoBoxContent,
  domainBox = false
}) {
  return (
    <FormGroup
      controlId={controlId}
      className={m(styles.formGroupContainer, className)}
    >
      <Form.Label className={styles.detailsLabel} htmlFor={controlId}>
        {label}
      </Form.Label>
      <Form.Control
        id={controlId}
        type={type}
        className={mergeIf(styles.detailsTextInput, {
          [styles.isInvalid]: input.isInvalid || isError
        })}
        value={input.value}
        onChange={input.onChange}
        onBlur={input.onValidate}
        isInvalid={input.isInvalid || isError}
        onFocus={onFocus}
        style={{
          height: 'calc(2.5rem + calc(var(--bs-border-width) * 2))',
          minHeight: 'calc(2.5rem + calc(var(--bs-border-width) * 2))'
        }}
      />
      {input.isInvalid && (
        <div className={styles.detailsErrorMessage}>
          <img src={errorIcon} alt="" /> <div className={styles.invalidFeedback}>{invalidFeedback}</div>
        </div>
      )}
      {infoBoxContent && (
        <div className={styles.detailsInfoBox}>
          <img src={infoIcon} alt="" /> <div className={styles.infoBoxContent}>{infoBoxContent}</div>
        </div>
      )}
      {domainBox && (
        <span
          style={{
            position: 'absolute',
            top: '37px',
            color: '#4c4c4c',
            right: '5px',
            border: '1px solid #e1e4e8',
            fontSize: '14px',
            padding: '0.35rem 0.25rem 0.1rem 0.25rem',
            background: '#f7f8f9'
          }}
        >
          .knowledge.ai
        </span>
      )}
    </FormGroup>
  );
}

DetailsField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  invalidFeedback: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  isError: PropTypes.bool,
  infoBoxContent: PropTypes.string,
  domainBox: PropTypes.bool
};
