import { Helmet } from 'react-helmet-async';
import leadfeeder from 'src/components/headerScripts/leadfeeder.js';
import googleTag from 'src/components/headerScripts/googleTag';

export default function HeaderScripts() {
  return <Helmet>
    <script>
      {leadfeeder}
    </script>

    {/* Google tag (gtag.js) */}
    <script async src='https://www.googletagmanager.com/gtag/js?id=G-B1EB9BEFCY' ></script>
    <script>
      {googleTag}
    </script>
  </Helmet>
}
