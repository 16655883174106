import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { m } from 'src/util/cssUtil';
import styles from 'src/pages/login/Login.module.css';
import tickIcon from 'src/img/tick_icon.svg'

export default function TenantList({ tenantList, selectedTenant, setSelectedTenant }) {
  const { t } = useTranslation();

  return (
    <Container className="mt-5">
      <Row>
        <Col md={12} className={m(styles.tenantButtonWrapper)}>
          <div className="pb-2">
            <p style={{ fontSize: '16px', fontWeight: 'bold', fontFamily: 'Helvetica Neue Black' }}>
              {t('tenant_list_message1')}
            </p>
            <p style={{ fontSize: '16px', fontWeight: '600' }}>
              {t('tenant_list_message2')}
            </p>
          </div>
          {tenantList.map((tenant, index) => (
            <Button
              style={{textAlign: 'left'}}
              key={index}
              variant="outline-primary"
              className={`w-100 mb-2 ${selectedTenant === tenant ? styles.activeBtn : ''}`}
              onClick={() => setSelectedTenant(tenant)}
            >
             {selectedTenant === tenant && (
                <img src={tickIcon} alt="Tick" />
              )} {tenant}
            </Button>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

TenantList.propTypes = {
  tenantList: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTenant: PropTypes.string,
  setSelectedTenant: PropTypes.func.isRequired,
};
