import { lazy } from 'react';
import { useLocation, Outlet } from "react-router";

import TinyPage from 'src/components/tinyPage/TinyPage';
import EntryPage from 'src/components/entryPage/EntryPage';
import Login from 'src/pages/login/Login';

const NotFound = lazy(() => import(
  /* webpackChunkName: "NotFound" */
  /* webpackPrefetch: true */
  'src/pages/notfound/NotFound'));

export default function Root() {
  const location = useLocation();

  return location.pathname === '/'
    ? <EntryPage mode='login'><Login /></EntryPage>
    : <Outlet />;
}