import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paths: [],
  email: null,
  tenantType: null,
  domain: null,
  direction: {
    previous: null,
    value: 'init',
  },
  backwardsError: {
    status: 'ok',
    returnPath: null,
  },
};

const slice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    allowPath(state, action) {
      if (!state.paths.includes(action.payload)) {
        state.paths.push(action.payload);
      }
    },
    setupEmail(state, action) {
      state.email = action.payload;
    },
    setupDomain(state, action) {
      state.domain = action.payload;
    },
    setupTenantType(state, action) {
      if (action.payload === '/try') {
        state.tenantType = 'trial';
      } else {
        state.tenantType = 'purchase';
      }
    },
    determineDirection(state, action) {
      const toSignUp = (path) => {
        if (path === '/buy' || path === '/try') {
          return '/sign-up';
        }

        return path;
      };

      const previous = toSignUp(action.payload.previous);
      const current = toSignUp(action.payload.pathname);

      const fullPaths = ['/sign-up', '/confirm', '/subscription', '/pay', '/waiting'];
      const diff = fullPaths.indexOf(current) - fullPaths.indexOf(previous);
      if (diff === 0) {
        state.direction.value = 'neutral';
      } else if (diff < 0) {
        state.direction.value = 'backwards';
      } else {
        state.direction.value = 'forwards';
      }

      state.direction.previous = action.payload.pathname;
    },
    openError(state, action) {
      state.direction.value = initialState.direction.value;

      state.backwardsError.returnPath = action.payload;
      state.backwardsError.status = 'error';
    },
    closeError(state) {
      state.backwardsError.status = 'ok';
      state.backwardsError.returnPath = null;
    },
    clearProgress(state) {
      state.direction = initialState.direction;

      state.paths = initialState.paths;
      state.email = initialState.email;
      state.domain = initialState.domain;
    },
  },
});

const journeySlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    paths: (state) => state[slice.name].paths,
    email: (state) => state[slice.name].email,
    domain: (state) => state[slice.name].domain,
    tenantType: (state) => state[slice.name].tenantType,
    direction: {
      previous: (state) => state[slice.name].direction.previous,
      value: (state) => state[slice.name].direction.value,
    },
    backwardsError: {
      status: (state) => state[slice.name].backwardsError.status,
      returnPath: (state) => state[slice.name].backwardsError.returnPath,
    },
  },
  rememberedKeys: [slice.name],
};

export default journeySlice;
