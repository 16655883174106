import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import DetailsField from 'src/pages/signup/detailsForm/DetailsField';

import styles from 'src/pages/login/Login.module.css';

export default function DetailsForm({
  emailInput,
  disabled,
  isError,
  onToConfirm,
  errorMessage
}) {
  const { t } = useTranslation();

  const getInvalidFeedback = () => {
    if (errorMessage === "userNotFound") {
      return t('user_not_found');
    } else {
      return t('invalid_email');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission action
    if(emailInput.isValid) {
      onToConfirm();
    }
  };
  return <>
    <Row className="mb-5">
      <Form className={styles.detailsForm} onSubmit={handleSubmit}>
        <DetailsField
          label={t('login_email')}
          type="email"
          placeholder={t('enter_login_email')}
          invalidFeedback={getInvalidFeedback()}
          controlId='formEmail'
          disabled={disabled}
          isError={isError}
          input={emailInput}
          errorMessage={errorMessage}
        />
      </Form>
    </Row >
  </>;
};

DetailsForm.propTypes = {
  emailInput: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  onToConfirm: PropTypes.func.isRequired
}