import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authParams: {
    domain: null,
    region: null,
  },
};

const slice = createSlice({
  name: 'thankYou',
  initialState,
  reducers: {
    saveAuthParams(state, action) {
      state.authParams = action.payload;
    },
  },
});

const thankYouSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    authParams: {
      domain: (state) => state[slice.name].authParams.domain,
      region: (state) => state[slice.name].authParams.region,
    },
  },
  rememberedKeys: [slice.name],
};

export default thankYouSlice;
