import { dinero, transformScale, halfUp, toDecimal } from 'dinero.js';
import { USD } from '@dinero.js/currencies';
import Decimal from 'decimal.js';

export const dineroFromDecimal = ({ amount: string, currency }) => {
  const scale = 14;
  const factor = currency.base ** scale;

  // `allocate` is to slow on large inputs,
  // So division has to be done here
  // Decimal is used to make it precise
  const decimal = new Decimal(string).dividedBy(100);
  const amount = decimal.times(factor).toNumber();

  return dinero({ amount, currency, scale });
};

export const formatUSD = (amount) => {
  const rounded = transformScale(amount, 2, halfUp);
  return toDecimal(rounded);
};

export const formatInteger = (number) => {
  const d = dinero({ amount: number, currency: USD });
  return toDecimal(d);
};

const moneyUtil = {
  dineroFromDecimal,
  formatUSD,
  formatInteger,
};

export default moneyUtil;
