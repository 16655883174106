import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import LoginInfo from 'src/components/loginPage/LoginInfo';
import LoginForm from 'src/pages/login/loginForm/LoginForm';
import TenantList from 'src/pages/login/tenant/TenantList';
import styles from 'src/pages/login/Login.module.css';
import SecondaryButton from 'src/components/secondaryButton/SecondaryButton';

export default function Login() {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(null);

  useEffect(() => {
    if (tenantList.length === 1) {
      setSelectedTenant(tenantList[0]);
    }
  }, [tenantList]);

  const handleTenantSelect = () => {
    if (selectedTenant) {
      const tenantUrl =
        selectedTenant.includes('http://') || selectedTenant.includes('https://')
          ? `${selectedTenant}/system/web/apps/kb/work/login/`
          : `https://${selectedTenant}/system/web/apps/kb/work/login/`;
      window.open(tenantUrl, '_blank');
    }
  };

  return (
    <div className={!isEmailValid ? styles.evenlySpacedVertical : styles.evenlySpacedVerticalTenant}>
      <LoginInfo />
      {!isEmailValid ? (
        <LoginForm setIsEmailValid={setIsEmailValid} setTenantList={setTenantList} />
      ) : (
        <>
          <TenantList tenantList={tenantList} selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant} />
          <div className="d-flex my-4" style={{ width: '100%' }}>
            <SecondaryButton className={styles.tenantButtons} onClick={() => setIsEmailValid(false)}>Back</SecondaryButton>
            <Button
              style={{ marginLeft: '2rem' }}
              className={styles.tenantButtons}
              variant="primary"
              onClick={handleTenantSelect}
              disabled={!selectedTenant}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
