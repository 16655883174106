import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';

import styles from 'src/components/loginPage/LoginInfo.module.css';

export default function PlanInfo() {
  const { t } = useTranslation();

  return <div className='mt-4 mb-5 d-flex flex-column justify-content-center'>
    <Row>
      <h1 className={styles.planTitle}>
        {t('login_title')}
      </h1>
    </Row>
    <Row className={styles.welcomeMessage}>
      <p className={styles.planDescription}>
      {t('login_welcome_message')}
      </p>
    </Row>
  </div>;
}
