import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import styles from 'src/components/endInfo/EndInfo.module.css';
import { m } from 'src/util/cssUtil';


export default function EndInfo({ className }) {
  const { t } = useTranslation();
  return <Row className={m(
    className,
    'center-block',
    'd-flex')}
  >
    <p className={m(styles.text, 'mb-4')}>
      {t('copy_right')}
    </p>
  </Row>
};

EndInfo.propTypes = {
  className: PropTypes.string,
}