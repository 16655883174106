import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trial: {
    remainingTime: {
      days: null,
    },
    expiryDate: null,
  },
  production: {
    currentPeriodEndDate: null,
    customer: null,
    subscription: null,
    status: null,
  },
  isTrialToProd: false, // New state for tracking trial to production conversion
};

const slice = createSlice({
  name: 'aboutSub',
  initialState,
  reducers: {
    loadSubscription(state, action) {
      const data = action.payload.body.data;
      state.trial = JSON.parse(JSON.stringify(initialState.trial));
      state.production = JSON.parse(JSON.stringify(initialState.production));

      if (data.expiryDate !== undefined) {
        state.trial.expiryDate = data.expiryDate;
        state.trial.remainingTime.days = data.remainingTime.days;
        return;
      }

      state.production.currentPeriodEndDate = data.currentPeriodEndDate;
      state.production.customer = data.customerId;
      state.production.subscription = data.stripeSubscriptionId;
      state.production.status = data.status;
    },
    updateStatus(state, action) {
      state.production.status = action.payload.status;
    },
    clearTrial(state) {
      state.trial = initialState.trial;
    },
    setIsTrialToProd(state, action) { // New action to update isTrialToProd state
      state.isTrialToProd = action.payload;
    },
  },
});

const aboutSubSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    trial: {
      remainingTime: {
        days: (state) => state[slice.name].trial.remainingTime.days,
      },
      expiryDate: (state) => state[slice.name].trial.expiryDate,
    },
    production: {
      currentPeriodEndDate: (state) => state[slice.name].production.currentPeriodEndDate,
      customer: (state) => state[slice.name].production.customer,
      subscription: (state) => state[slice.name].production.subscription,
      status: (state) => state[slice.name].production.status,
    },
    isTrialToProd: (state) => state[slice.name].isTrialToProd, // New selector for isTrialToProd
  },
  rememberedKeys: [],
};

export default aboutSubSlice;
