import React, { Suspense } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from 'src/pages/redirect/Redirect.module.css';
import { m } from 'src/util/cssUtil';
import egainLogo from 'src/img/egainLogo.png';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';


export default function RedirectPage() {
  const { t } = useTranslation();
  //   const navigate = useNavigate();
  //   const handleLogin = () => {
  //     navigate('/myaccount?domain=ps1.egain.cloud&region=NA');
  //   };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Container fluid className={m(styles.pageBox, 'd-flex', 'align-items-center', 'justify-content-center')}>
        <Row>
          <Col className={m('text-center', styles.contentContainer)}>
            <img
              src={egainLogo}
              alt="eGain Logo"
              className={`${styles.logo} mb-4`}
            />
            <p className={styles.redirectMessage}>{t('redriect_message')}</p>
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
}