import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import EndInfo from 'src/components/endInfo/EndInfo';
import useTextInput from 'src/hooks/useTextInput/useTextInput';
import DetailsForm from 'src/pages/login/loginForm/DetailsForm';
import SpinnerAfter from 'src/components/spinner/SpinnerAfter';
import styles from 'src/pages/login/Login.module.css';
import { m } from 'src/util/cssUtil';
import getUserTenants from 'src/apis/getUserTenants/getUserTenants';
import { set } from 'date-fns';

export default function LoginForm({ setIsEmailValid, setTenantList }) {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isInputdisabled, setIsInputDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const queryClient = useQueryClient();
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  const emailInput = useTextInput({ regex: emailRegex });

  useEffect(() => {
    const fetchData = async () => {
      if (emailInput.isValid) {
        try {
          const data = await queryClient.fetchQuery({
            queryKey: [getUserTenants.key, emailInput.value],
            queryFn: () => getUserTenants.fn(emailInput.value),
          });
          setTenantList(data.body);
          setIsEmailValid(true);
        } catch (error) {
          console.error('Failed to fetch tenant data:', error.response.status, error);
          if (error.response.status === 404) {
            setErrorMessage('userNotFound'); // Set errorType to indicate user not found
          }
          setIsError(true);
        } finally {
          setIsFetching(false);
          setIsInputDisabled(false);
        }
      } else {
        setIsFetching(false);
      }
    };

    if (isFetching) {
      fetchData();
    }
  }, [isFetching, emailInput.value, emailInput.isValid, queryClient, setIsEmailValid, setTenantList]);

  const onToConfirm = () => {
    setIsInputDisabled(true);
    setIsFetching(true);
  };

  return (
    <>
      <DetailsForm emailInput={emailInput} disabled={isInputdisabled} isError={isError} onToConfirm={onToConfirm} errorMessage={errorMessage} />
      <Row className='mb-4 d-flex'>
        <div className={m(styles.detailsButtonWrapper, 'd-flex')}>
          {
            isFetching ? (
              <div className={styles.spinnerDiv}><SpinnerAfter /></div>
            ) : (
              <Button
                type="button"
                variant="primary"
                onClick={onToConfirm}
                disabled={!emailInput.isValid}
                className={m(styles.nextButton, 'flex-grow-1', 'mb-4')}
              >
                {t('next_button')}
              </Button>
            )
          }
        </div>
        <EndInfo />
      </Row>
    </>
  );
}

LoginForm.propTypes = {
  setIsEmailValid: PropTypes.func.isRequired,
  setTenantList: PropTypes.func.isRequired
};
