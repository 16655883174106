import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  period: {
    current: {
      startDate: null,
      endDate: null,
    },
    upcoming: {
      startDate: null,
      endDate: null,
    },
  },

  total: null,
  amountDue: null,

  credit: {
    available: null,
    remaining: null,
  },
  status: {
    stripeProducts: 'loading',
  },
};

const slice = createSlice({
  name: 'modify',
  initialState,
  reducers: {
    loadPreview(state, action) {
      const data = action.payload.body.data;
      state.period.current.startDate = data.currentPeriodStartDate;
      state.period.current.endDate = data.currentPeriodEndDate;

      state.period.upcoming.startDate = data.upcomingPeriodStartDate;
      state.period.upcoming.endDate = data.upcomingPeriodEndDate;

      state.total = data.totalUpcoming;
      state.amountDue = data.totalDueNow;

      state.credit.available = data.availableCredit;
      state.credit.remaining = data.remainingCredit;
    },
    updateStripeProductsStatus(state, action) {
      state.status.stripeProducts = action.payload;
    },
  },
});

const modifySlice = {
  initialState,
  reducers: { [slice.name]: slice.reducer },
  actions: slice.actions,
  select: {
    period: {
      current: {
        startDate: (state) => state[slice.name].period.current.startDate,
        endDate: (state) => state[slice.name].period.current.endDate,
      },
      upcoming: {
        startDate: (state) => state[slice.name].period.upcoming.startDate,
        endDate: (state) => state[slice.name].period.upcoming.endDate,
      },
    },

    total: (state) => state[slice.name].total,
    amountDue: (state) => state[slice.name].amountDue,

    credit: {
      available: (state) => state[slice.name].credit.available,
      remaining: (state) => state[slice.name].credit.remaining,
    },

    status: {
      stripeProducts: (state) => state[slice.name].status.stripeProducts,
    },
  },
  rememberedKeys: [],
};

export default modifySlice;
