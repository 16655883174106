import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import journeySlice from 'src/components/journey/journeySlice';
import SecondaryButton from 'src/components/secondaryButton/SecondaryButton';

import styles from 'src/components/entryPage/ellipsisMenu/EllipsisMenu.module.css';

const EllipsisMenu = () => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleItemClick = () => setShowDropdown(false);

  const dropdownClass = showDropdown ? `${styles.dropdownMenu} ${styles.show}` : styles.dropdownMenu;
  const tenantType = useSelector(journeySlice.select.tenantType);
  const typeButtonText = tenantType === 'trial'
    ? t('buy_now')
    : t('free_trial');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSwitchType = () => {
    dispatch(journeySlice.actions.clearProgress());
    handleItemClick();
    if (tenantType === 'trial') {
      navigate('/buy');
    } else {
      navigate('/try');
    }
  }

  return <div className={styles.customDropdown}>
    <Button variant="link" onClick={toggleDropdown} className={styles.ellipsisToggle}>
      ...
    </Button>

    <div className={dropdownClass}>
      <SecondaryButton
        className={styles.dropdownButton}
        onClick={() => navigate('/login')}
      >
        {t('login')}
      </SecondaryButton>
      <Button
        className={styles.dropdownButton}
        type="button"
        variant="primary"
        onClick={onSwitchType}
      >
        {typeButtonText}
      </Button>
    </div>
  </div>
};

export default EllipsisMenu;
