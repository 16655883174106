import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import PropTypes from 'prop-types';

import styles from 'src/components/tinyPage/TinyPage.module.css';
import { m } from 'src/util/cssUtil';

export default function TinyPage({ children }) {
  return <div className={m(
    styles.pageBox,
    'd-flex',
    'align-items-center',
    'justify-content-center')}
  >
    <Container as='main' className={styles.contentContainer}>
      <Suspense>
        {children}
      </Suspense>
    </Container>
  </div>
}

TinyPage.propTypes = {
  children: PropTypes.element.isRequired,
}