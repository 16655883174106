export const m = (...classNames) => classNames.join(' ');

export const mergeIf = (...classNames) =>
  classNames
    .map((name) => {
      if(typeof name === 'undefined') {
        return '';
      }

      if (typeof name === 'string') {
        return name;
      }

      const [keyName, condValue] = Object.entries(name)[0];
      if (condValue !== false) {
        return keyName;
      }

      return `DISABLED-${keyName}`;
    })
    .join(' ');
