import { useState } from 'react';

export default function useTextInput({ regex, initialValue = '' }) {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  // An input is only invalid when its been blurred,
  // But is valid whenever the value is valid
  const onChange = (e) => {
    setIsValid(e.target.value.match(regex) !== null);
    setValue(e.target.value);
  };

  const onValidate = () => {
    setIsInvalid(value.match(regex) === null);
  };

  const onClear = () => {
    setValue('');
    setIsValid(false);
    setIsInvalid(false);
  };

  return {
    value,
    isValid,
    isInvalid,
    onClear,
    onChange,
    onValidate,
  };
}
