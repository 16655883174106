import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import 'src/custom.scss';
import 'react-loading-skeleton/dist/skeleton.css';
// Put any other imports below so that CSS from 
// components takes precedence over default styles
import 'src/global.css';

import initStore from 'src/store';
import initI18n from 'src/languages/i18n';
import routes from 'src/routes';
import HeaderScripts from 'src/components/headerScripts/HeaderScripts';

const router = createBrowserRouter(routes, { basename: process.env.PUBLIC_URL });
// const router = createBrowserRouter(routes);
const store = initStore({ driver: window.sessionStorage });
initI18n();

export const queryConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  }
};

const queryClient = new QueryClient(queryConfig);

export default function App() {
  return <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <HeaderScripts />

      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>;
}