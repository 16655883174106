import PropTypes from 'prop-types';

import styles from 'src/components/entryPage/sidebar/Sidebar.module.css';
import { m } from 'src/util/cssUtil';

export default function Sidebar({ children }) {
  return <div className={m(styles.sidebarRow)}>
    {children}
  </div>;
}

Sidebar.propTypes = {
  children: PropTypes.node,
}