import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

export default function FormGroup({ controlId, children, as, className }) {
  return <Form.Group controlId={controlId} className={className} as={as}>
    {children}
  </Form.Group >;
}

FormGroup.propTypes = {
  controlId: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  as: PropTypes.object,
  className: PropTypes.string,
}