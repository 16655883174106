import { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import AdminHeader from 'src/components/adminPage/AdminHeader';
import PropTypes from 'prop-types';

import styles from 'src/components/adminPage/AdminPage.module.css';
import { m } from 'src/util/cssUtil';

export default function LargeAdminPage({ children }) {
  return <div className={m(styles.pageBox, 'd-flex', 'flex-column')}>
    <AdminHeader />
    {/* This div is required,
     So the content can be separated from the header on short displays */}
    <main
      className='flex-grow-1 d-flex justify-content-center flex-column py-3'
    >
      <Container className={m(
        styles.smallContainer,
        'd-flex',
        'flex-column',
        'flex-grow-1',
        'justify-content-center')}
        fluid
      >
        <Suspense>
          {children}
        </Suspense>
      </Container>
    </main>
  </div>
}

LargeAdminPage.propTypes = {
  children: PropTypes.element.isRequired,
}