import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientSecret: null,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    loadClientSecret(state, action) {
      state.clientSecret = action.payload;
    },
  },
});

const checkoutSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    clientSecret: (state) => state[slice.name].clientSecret,
  },
  rememberedKeys: [slice.name],
};

export default checkoutSlice;
